export function sendMail(body) {
    const requestBody = {
      service_id: "PPMSelling",
      template_id: "PPMSelling",
      user_id: "user_Nz762tKWkr203TH90kNqr",
      accessToken: "948ff5d2f9e7096b149ad9c0dc642fb8",
      template_params: {
        Body: body.Body,
        Subject: body.Subject,
        FromEmail: body.FromEmail,
        FromName: body.FromName,
      },
    };
    return new Promise(function (resolve, reject) {
      fetch("/api/smtp", {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => {
          console.log("res::", res);
          resolve(res.json());
        })
        .catch((e) => {
          console.log("err::", e);
          reject(e);
        });
    });
  }
  