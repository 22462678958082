import React from 'react';
import pallette from '@Assets/Styles/pallette';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import { sendMail } from "@Utils/SMTPService";
import { createTheme, ThemeProvider } from '@mui/material/styles';


const h1Style = {
    textAlign: 'left',
    fontFamily: 'SourceHanSans', fontSize: 28, fontWeight: 700,
    textShadow: `2px 2px 2px #303030A0`,
    marginBottom: 12,
    color: 'white'
}

const h2Style = {

    fontSize: 18,
    textShadow: `1px 1px 1px #303030A0`,
    textAlign: 'left',
    fontFamily: 'sans-serif',
    marginBottom: 12,
    color: 'white'
}

export default () => {
    const [hasSentEmail, setHasSentEmail] = React.useState(false);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const theme = createTheme({
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85',
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
            white: {
                main: '#FFFFFF',
                contrastText: '#ffcc00',
            }
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <div id={'contactUs'} style={{width:'100%'}}>
                <Grid container justify={'center'} >
                    <Grid item xs={0} sm={0} md={2} lg={3} />
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                        <div style={{ ...h1Style }} >{"联系我们"}</div>
                        <div style={{ ...h2Style }}>
                            {
                                "感谢您的关注。如果您希望了解更多，或者您有任何需要咨询的问题，可以在此留言，我们会尽快和您取得联系。"
                            }
                        </div>
                        <form>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField

                                        label="您的名字"
                                        id="name"
                                        variant="standard"
                                        fullWidth
                                        value={name}
                                        color="white"
                                        InputLabelProps={{
                                            style: { color: '#F0F0F0' }
                                        }}
                                        InputProps={{
                                            style: { color: 'white', borderBottom: '1px solid rgba(1, 1, 1, 0.42)' }
                                        }}
                                        onChange={(value) => { setName(value.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        label="您的Email"
                                        //color={'white'}
                                        id="email"
                                        variant="standard"
                                        fullWidth
                                        value={email}
                                        color="white"
                                        InputLabelProps={{
                                            style: { color: '#F0F0F0' }
                                        }}
                                        InputProps={{
                                            style: { color: 'white', borderBottom: '1px solid rgba(1, 1, 1, 0.42)' }
                                        }}

                                        onChange={(value) => { setEmail(value.target.value) }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        label="您的留言"
                                        id="message"
                                        variant="standard"
                                        //color={'white'}
                                        fullWidth
                                        //className: classes.textArea,
                                        multiline
                                        rows='5'
                                        value={message}
                                        color="white"
                                        InputLabelProps={{
                                            style: { color: '#F0F0F0' }
                                        }}
                                        InputProps={{
                                            style: { color: 'white', borderBottom: '1px solid rgba(1, 1, 1, 0.42)' }
                                        }}

                                        onChange={(value) => { setMessage(value.target.value) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <div style={{
                                        userSelect: 'none',
                                        cursor: 'pointer',
                                        height: 48,
                                        width: 120,
                                        borderRadius: 8,
                                        backgroundColor: "white",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'black',
                                        fontFamily: 'SourceHanSans',
                                        fontSize: 18,
                                        fontWeight: 500,
                                        boxShadow: '4px 4px 4px #303030'
                                    }}
                                        onClick={() => {
                                            if (message && name && email && !hasSentEmail) {
                                                sendMail({
                                                    Body: message,
                                                    FromEmail: email,
                                                    FromName: name,
                                                })
                                                    .then((res) => {
                                                        setHasSentEmail(true);
                                                        alert("感谢您的关注，我们已收到您的留言。");
                                                        setEmail('')
                                                        setMessage('')
                                                        setName('')
                                                    })
                                                    // TODO:这个地方就算发信成功了接口也会反一个ParseError，怪
                                                    .catch((e) => {
                                                        setHasSentEmail(true);
                                                        alert("感谢您的关注，我们已收到您的留言。");
                                                        setEmail('')
                                                        setMessage('')
                                                        setName('')
                                                    });
                                            } else {
                                                if (hasSentEmail) {
                                                    alert("请勿重复操作");
                                                } else {
                                                    alert("请输入您的联系方式。");
                                                }
                                            }
                                        }}
                                    >
                                        {"提交"}
                                    </div>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                    <Grid item xs={0} sm={0} md={2} lg={3} />
                </Grid>
            </div>
        </ThemeProvider>

    )
}