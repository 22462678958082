
import React from 'react';

import useViewport from '@Utils/UseViewport';
import MaskImageText from '@Components/MaskImageText';

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.css";
import gradient from '@Assets/Images/gradient.jpg';
import carouselBG1 from '@Assets/Images/bg1.jpg';
import carouselBG2 from '@Assets/Images/bg2.jpg';
import carouselBG3 from '@Assets/Images/bg3.jpg';

export default (props) => {
  const { width: windowWidth, height: windowHeight } = useViewport();
  const carouselItemStyle = {
    height: 810,
    width: windowWidth,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
  const carouselContainerStyle = {
    height: '100%',
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
  const h1Style = {
    color: 'white',
    textShadow: "4px 4px 4px #30303090",
    fontFamily: 'SansSourceHans',
    fontWeight: 700,
    fontSize: 48,
    marginBottom: 16,
    lineHeight: 1.4
  }
  const h2Style = {
    color: 'white',
    textShadow: "4px 4px 4px #30303090",
    fontFamily: 'SansSourceHans',
    fontWeight: 700,
    fontSize: 28,
    marginBottom: 16,
    lineHeight: 1.6
  }
  const gradientBlockStyle = {
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center'
  }
  const gradientTextStyle = {
    ...gradientBlockStyle,
    WebkitBackgroundClip: 'text',
    color: "transparent",
    textFillColor: 'transparent',
    //textShadow: 'none'
  }
  return (
    <Carousel
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      interval={3000}
      showStatus={false}
      showIndicators={false}

    >
      <div style={{ ...carouselItemStyle, backgroundImage: `url(${carouselBG1})` }}>
        <div style={{
          ...carouselContainerStyle,
          backgroundColor: 'rgba(0,0,0,0.3)'
        }}>
          <div style={{ ...h1Style }}>
            沈阳盛京行科技有限公司
            </div>
          <div style={{ ...h2Style }}>
            国家级高新技术企业·专业软件定制开发
            </div>
        </div>
      </div>
      <div style={{ ...carouselItemStyle, backgroundImage: `url(${carouselBG2})` }}>
        <div style={{ ...carouselContainerStyle }}>
          <div style={{
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start"
          }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              专注于软件、应用<br />
                定制开发解决方案
              </div>
            <div style={{ ...gradientBlockStyle, height: 8, width: 240, marginBottom: 12 }} />
            <div style={{ ...h2Style, textAlign: 'left', marginBottom: 8 }}>
              门户网站·业务软件·管理系统<br />
                桌面应用·移动应用APP·微信小程序
              </div>
            <div style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { props.link1Action() }}>
              <MaskImageText
                text={'更多软件高端定制'}
                textStyle={h2Style}
                maskImageUrl={gradient}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ ...carouselItemStyle, backgroundImage: `url(${carouselBG3})` }}>
        <div style={{ ...carouselContainerStyle }}>
          <div style={{
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-end"
          }}>
            <div style={{ ...h1Style, marginBottom: 12 }}>
              重点难点<br />
                项目攻克
              </div>
            <div style={{ ...gradientBlockStyle, height: 8, width: 240, marginBottom: 12 }} />
            <div style={{ ...h2Style, textAlign: 'right', marginBottom: 8 }}>
              虚拟仿真·智慧城市·企业数字化转型<br />
                人工智能训练与开发·大数据采集与分析<br />
                业界前沿技术研发与应用
              </div>
            <div style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { props.link2Action() }}>
              <MaskImageText
                text={'——联系我们'}
                textStyle={h2Style}
                maskImageUrl={gradient}
              />
            </div>
          </div>
        </div>
      </div>
    </Carousel >
  )
}