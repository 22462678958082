import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';

import ua from '@Utils/GetUserAgent';

import { Provider } from "react-redux";
import store from "@Redux/Store";

import App_PC from '~/App_PC';
import App_MO from '~/App_MO';

import { ParallaxProvider, useParallax } from 'react-scroll-parallax';

const App = () => {
  // document.body.addEventListener('touchmove', (e) => {
  //   e.preventDefault();
  // }, { passive: false });
  if (ua.android || ua.ios) {
    return <App_MO />
  } else {
    return <App_PC />
  }
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ParallaxProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ParallaxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
