import React from 'react';

export default (props) => {
    const gradientBlockStyle = {
        backgroundImage: `url(${props.maskImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '200% 100%',
        backgroundPosition: 'center'
    }
    const gradientTextStyle = {
        ...gradientBlockStyle,
        WebkitBackgroundClip: 'text',
        color: "transparent",
        textFillColor: 'transparent',
        textShadow: 'none'
    }
    const OverlayText = () => {
        return (
            <div style={{ ...props.textStyle, position: "absolute", color: 'transparent', top: 0, zIndex: 9 }}>
                {props.text}
            </div>
        )
    }
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ ...props.textStyle, ...gradientTextStyle, position: "relative", zIndex: 10, display: 'flex' }}>
                {props.text}
            </div>
            <OverlayText />
        </div>
    )
}

/*
1.用text做遮罩clip背景图片
2.复制一层叠到原位，zIndex设一下放到底下
3.给复制出来这层加阴影
*/