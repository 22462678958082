import React from 'react';
import pallette from '@Assets/Styles/pallette';

import { AppBar } from '@mui/material';
import VisibilitySensor from "react-visibility-sensor";

import '@Assets/Styles/header.css';
import { animated, useSpring, useSpringRef } from '@react-spring/web';

import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Divider } from 'antd';

import 'antd/dist/antd.css';
import mainBG from "@Assets/Images/BGS_01.png";

const logo = require("@Assets/Images/logo.png");




const NavLink = (props) => {
    return (
        <div
            className={'navLink'}
            style={{
                width: "100%",
                fontSize: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 700,
                fontFamily: "SourceHanSans",
                color: "white",
                textDecoration: 'none',
                cursor: 'pointer',
                textShadow: `1px 1px 1px #303030A0`,
            }}
            onClick={() => {
                if (props.anchor) {
                    // var anchorEl = document.getElementById(props.anchor);
                    // anchorEl.scrollIntoView();
                    props.scrollToSec(props.anchor);
                } else {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                props.closeDrawer();
                //props.setPageIndex(props.targetIndex);
            }}
        >

            {props.label}

        </div>

    )
}



export default (props) => {
    const [drawerVisible, setDrawerVisible] = React.useState(false);

    const [scrollLock, setScrollLock] = React.useState(false);

    const [pageYLeftTop, setPageYLeftTop] = React.useState(false);



    const floatHeaderHideStyle = {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: "white"
    }
    const floatHeaderShowStyle = {
        backgroundColor: 'white',
        boxShadow: `8px 8px 8px ${pallette.black30}`,
        color: pallette.black30
    }

    const headerTransRef = useSpringRef();
    const headerTrans = useSpring({
        from: {
            ...floatHeaderHideStyle
        },
        ref: headerTransRef
    });




    const pageTo = (toIndex) => {
        const _index = toIndex;
        if (_index >= 0 && !scrollLock) {
            props.setPageIndex(toIndex);
            setScrollLock(true);
            setTimeout(() => {
                setScrollLock(false);
            }, 1500);
        }
    }


    return (



        <>
            <Drawer
                open={drawerVisible}
                placement={'right'}
                onClose={() => { setDrawerVisible(false) }}
                width={180}
                headerStyle={{
                    height: 0,
                    width: 0
                }}
                contentWrapperStyle={{
                    boxShadow:'-4px 0px 4px  #000000A0',
                }}
                bodyStyle={{
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingTop: 16,
                    backgroundImage: `url(${mainBG})`,
                    backgroundPosition: '18% 40%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    marginTop: -36
                    //backgroundColor:'#F0F0F0'
                }}>
                <NavLink
                    label={'首页'}
                    anchor={'top'}
                    scrollToSec={props.scrollToSec}
                    closeDrawer={() => { setDrawerVisible(false) }}
                />
                <Divider type={'horizontal'} style={{
                    backgroundColor: 'white',
                    marginTop: 12,
                    marginBottom: 12
                }} />
                <NavLink
                    label={'业务范围'}
                    anchor={1}
                    scrollToSec={props.scrollToSec}
                    closeDrawer={() => { setDrawerVisible(false) }}
                />
                <Divider type={'horizontal'} style={{
                    backgroundColor: 'white',
                    marginTop: 12,
                    marginBottom: 12
                }} />
                <NavLink
                    label={'项目案例'}
                    anchor={2}
                    scrollToSec={props.scrollToSec}
                    closeDrawer={() => { setDrawerVisible(false) }}
                />
                <Divider type={'horizontal'} style={{
                    backgroundColor: 'white',
                    marginTop: 12,
                    marginBottom: 12
                }} />
                <NavLink
                    label={'联系我们'}
                    anchor={'bottom'}
                    scrollToSec={props.scrollToSec}
                    closeDrawer={() => { setDrawerVisible(false) }}
                />
            </Drawer>
            <VisibilitySensor
                partialVisibility={true}
                active={true}
                onChange={(isVisible) => {
                    console.log(isVisible);
                    if (isVisible && pageYLeftTop) {
                        headerTransRef.start({
                            from: floatHeaderShowStyle,
                            to: floatHeaderHideStyle
                        })
                    } else if (!isVisible && !pageYLeftTop) {
                        headerTransRef.start({
                            from: floatHeaderHideStyle,
                            to: floatHeaderShowStyle
                        })
                    }
                    setPageYLeftTop(!isVisible);
                }}
            >
                <div style={{
                    height: 1, width: '100%', position: 'absolute', top: 64, zIndex: 100
                }} />
            </VisibilitySensor>

            <animated.div

                style={{
                    height: 48,
                    position: 'fixed',
                    width: '100%',
                    zIndex: 99,
                    transformStyle: 'preserve-3d',
                    ...headerTrans,
                    fontFamily: 'SourceHanSans'
                }}
            >
                <div style={{
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <div style={{ height: '100%', display: 'flex', flexGrow: 2, flexBasis: 0, alignItems: 'center', justifyContent: 'flex-start', marginLeft: 20, fontSize: 18 }}>
                        <div style={{ height: 24, width: 24, backgroundImage: `url(${logo})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', marginRight: 8 }} />
                        <div style={{
                            width: 200,
                        }}>盛京行科技有限公司</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: 64, flexGrow: 1, flexBasis: 0 }} >

                    </div>
                    <div
                        style={{ marginRight: 20, paddingTop: 6 }}
                        onClick={() => { setDrawerVisible(true) }}
                    >
                        <MenuIcon style={{ fontSize: 24, color: pageYLeftTop ? pallette.black30 : "white" }} />
                    </div>

                </div>
            </animated.div>
        </>


    )
}