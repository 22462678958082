import React from 'react';
import pallette from '@Assets/Styles/pallette';

import { AppBar } from '@mui/material';
import VisibilitySensor from "react-visibility-sensor";

import '@Assets/Styles/header.css';
import { animated, useSpring, useSpringRef } from '@react-spring/web';

const logo = require("@Assets/Images/logo.png");


const NavLink = (props) => {
  return (
    <div
      className={'navLink'}
      style={{
        width: 96,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: props.highlightIndexes.indexOf(props.pageIndex) >= 0 ? (700) : (500),
        color: props.highlightIndexes.indexOf(props.pageIndex) >= 0 ?
          (props.pageLeftTop ? 'rgb(49, 59, 111)' : pallette.smokeBlue)
          : (props.pageLeftTop ? pallette.black30 : "white"),
        textDecoration: 'none',
        cursor: 'pointer'
      }}
      onClick={() => {
        if (props.anchor) {
          // var anchorEl = document.getElementById(props.anchor);
          // anchorEl.scrollIntoView();
          props.scrollToSec(props.anchor);
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
        //props.setPageIndex(props.targetIndex);
      }}
    >

      {props.label}

    </div>

  )
}


export default (props) => {
  const [scrollLock, setScrollLock] = React.useState(false);

  const [pageYLeftTop, setPageYLeftTop] = React.useState(false);



  const floatHeaderHideStyle = {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: "white"
  }
  const floatHeaderShowStyle = {
    backgroundColor: 'white',
    boxShadow: `8px 8px 8px ${pallette.black30}`,
    color: pallette.black30
  }

  const headerTransRef = useSpringRef();
  const headerTrans = useSpring({
    from: {
      ...floatHeaderHideStyle
    },
    ref: headerTransRef
  });




  const pageTo = (toIndex) => {
    const _index = toIndex;
    if (_index >= 0 && !scrollLock) {
      props.setPageIndex(toIndex);
      setScrollLock(true);
      setTimeout(() => {
        setScrollLock(false);
      }, 1500);
    }
  }


  return (



    <>
      <VisibilitySensor
        partialVisibility={false}
        active={true}
        onChange={(isVisible) => {
          console.log(isVisible);
          if (isVisible && pageYLeftTop) {
            headerTransRef.start({
              from: floatHeaderShowStyle,
              to: floatHeaderHideStyle
            })
          } else if (!isVisible && !pageYLeftTop) {
            headerTransRef.start({
              from: floatHeaderHideStyle,
              to: floatHeaderShowStyle
            })
          }
          setPageYLeftTop(!isVisible);
        }}
      >
        <div style={{
          height: 1, width: '100%', position: 'absolute', top: 64, zIndex: 100
        }} />
      </VisibilitySensor>

      <animated.div

        style={{
          height: 64,
          position: 'fixed',
          width: '100%',
          zIndex: 99,
          transformStyle: 'preserve-3d',
          ...headerTrans,
          fontFamily: 'SourceHanSans'
        }}
      >
        <div style={{
          width: '100%',
          height: "100%",
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <div style={{ height: '100%', display: 'flex', flexGrow: 2, flexBasis: 0, alignItems: 'center', justifyContent: 'flex-start', marginLeft: 48, fontSize: 20 }}>
            <div style={{ height: 32, width: 32, backgroundImage: `url(${logo})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', marginRight: 8 }} />
              盛京行科技有限公司
              </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: 64 }} >
            <NavLink
              highlightIndexes={[0]}
              targetIndex={0}
              pageIndex={props.pageIndex}
              setPageIndex={pageTo}
              pageLeftTop={pageYLeftTop}
              label={'首页'}
              anchor={'top'}
              scrollToSec={props.scrollToSec}
            />
            <NavLink
              highlightIndexes={[1, 2]}
              targetIndex={1}
              pageIndex={props.pageIndex}
              setPageIndex={pageTo}
              pageLeftTop={pageYLeftTop}
              label={'业务范围'}
              anchor={1}
              scrollToSec={props.scrollToSec}
            />
            <NavLink
              highlightIndexes={[3, 4]}
              targetIndex={3}
              pageIndex={props.pageIndex}
              setPageIndex={pageTo}
              pageLeftTop={pageYLeftTop}
              label={'项目案例'}
              anchor={2}
              scrollToSec={props.scrollToSec} />
            <NavLink
              highlightIndexes={[7]}
              targetIndex={7}
              pageIndex={props.pageIndex}
              setPageIndex={pageTo}
              pageLeftTop={pageYLeftTop}
              label={'联系我们'}
              anchor={'bottom'}
              scrollToSec={props.scrollToSec} />
          </div>

        </div>
      </animated.div>
    </>


  )
}