import { createSlice } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState: {
        isLoggedIn: false,
        userInfo: {
        }
    },
    reducers: {
        save: (state, action) => {
            console.log(action);
            state.userInfo = action.payload.userInfo;
            state.isLoggedIn = true;
        },
        clear: state => {
            state.isLoggedIn = false;
            state.userInfo = {};
        }
    }
});

export const { save, clear } = userInfoSlice.actions;
export default userInfoSlice.reducer;