import React from 'react';
import Grid from '@mui/material/Grid';

import useViewport from '@Utils/UseViewport';
import pallette from '@Assets/Styles/pallette';
import MaskImageText from '@Components/MaskImageText';
import gradient from '@Assets/Images/gradient.jpg';


const avatar1 = require('@Assets/Images/avatar1.png');
const avatar2 = require('@Assets/Images/avatar2.png');
const avatar3 = require('@Assets/Images/avatar3.png');

const avatarStyle = {
  height: 144, width: 144, borderRadius: 72, marginBottom: 12,
  objectFit: 'cover',
  objectPosition: 'center', userSelect: 'none', cursor: 'pointer'
}

const h1Style = {
  display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center',
  fontFamily: 'SourceHanSans', fontSize: 32, fontWeight: 700,
  textShadow: `1px 1px 1px #303030A0`
}

const h2Style = {

  fontSize: 22,
  textShadow: `1px 1px 1px #303030A0`,
  textAlign: 'center',
  fontFamily: 'SourceHanSans', userSelect: 'none', cursor: 'pointer'
}

const pStyle = {
  display: "flex",
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: 14,
  //marginTop: 16,
  marginBottom: 24,
  fontFamily: 'sans-serif',
  color: '#666666',
  lineHeight: 1.8,
  userSelect: 'none', cursor: 'pointer'
}

export default () => {
  const { width: windowWidth, height: windowHeight } = useViewport();

  return (
    <div
      style={{
        width: "100%"
      }}>
      <div style={{ height: 48 }} />
      <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <div style={{
            ...h1Style
          }}>
            项目案例
          </div>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <div style={{ height: 48 }} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://prop.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={avatar1} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://prop.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              物业服务SaaS管理平台
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://prop.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>“互联网+社区”理念的应用</><br />
                <>让企业业务更高效，让住户生活更便捷</><br />
                <>放大资源整合的价值，探索全新业态</>
                <br />
                <br />
                <MaskImageText
                  text={'查看详情'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </>
            </div>
          </div>
        </Grid>


      </Grid>
      <div style={{ height: 48 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://taxi.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={avatar3} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://taxi.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              出租车管理与调度系统
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://taxi.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>出租车管理智慧解决方案</><br />
                <>AI实时分析路况与用车需求</><br />
                <>应用大数据的力量为出租车行业赋能</>
                <br />
                <br />
                <MaskImageText
                  text={'查看详情'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </>
            </div>
          </div>
        </Grid>

      </Grid>
      <div style={{ height: 48 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://tank.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <img src={avatar2} style={{
              ...avatarStyle
            }} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://tank.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{
              ...h2Style
            }}>
              装甲车辆虚拟仿真模拟训练系统
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div onClick={() => { window.open('https://tank.shengjingxing.com', '_blank') }} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <div style={{ ...pStyle }}>
              <>
                <>为独特业务全面定制解决方案</><br />
                <>设施、环境、物理性质1:1仿真模拟</><br />
                <>精准完成项目方业务需求</>
                <br />
                <br />
                <MaskImageText
                  text={'查看详情'}
                  textStyle={h2Style}
                  maskImageUrl={gradient}
                />
              </>
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ height: 48 }} />
    </div>
  )
}